<template>
  <div class="p-4">
    <!-- Button to open modal to add a new method -->
    <div class="flex justify-end mb-4">
      <div class="relative">
        <button
          @click="toggleModal"
          class="self-settings-add-btn flex items-center"
        >
          <span class="text-lg mr-1">+</span> Add Method
        </button>

        <!-- Modal Dialog for Adding New Method -->
        <div
          v-if="showModal"
          class="absolute right-full mr-2 top-0 bg-white border border-gray-200 rounded-lg shadow-md p-3 pb-2.5 z-10 w-64"
        >
          <!-- Method Name Input -->
          <input
            v-model="newMethod.name"
            type="text"
            placeholder="Enter method name"
            class="w-full mb-3 px-2 py-1 border rounded text-xs custom-focus"
          />
          <div class="flex justify-end space-x-2">
            <!-- Cancel Button -->
            <button
              @click="toggleModal"
              class="ml-2 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-1 px-2 rounded text-xs self-settings-cancel-btn"
            >
              Cancel
            </button>
            <!-- Add Method Button -->
            <button
              @click="addMethod"
              class="text-white font-bold py-1 px-2 rounded text-xs self-settings-add-btn"
              :class="
                !newMethod.name
                  ? 'cursor-not-allowed bg-blue-300'
                  : 'bg-blue-400 hover:bg-blue-500'
              "
              :disabled="!newMethod.name"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Displaying added methods -->
    <div v-for="(method, index) in localMethods" :key="index">
      <div class="rounded-lg py-2 flex flex-col gap-2 mb-3">
        <!-- Method Name Input Field -->
        <div class="flex items-center gap-4">
          <label class="text-gray-700 font-medium w-16">Name:</label>
          <input
            v-model="method.name"
            type="text"
            class="flex-grow px-3 py-2 border border-gray-300 custom-focus rounded-md"
            placeholder="Enter method name"
            @input="updateMethodData(index, 'name', $event.target.value)"
          />
        </div>
        <!-- Method Body Input Field (Template Editor) -->
        <div class="flex items-center gap-4">
          <label class="text-gray-700 font-medium text-left ml-3">Body:</label>
          <div class="zoom-view-fixed w-full border rounded-md p-2 ml-3">
            <!-- Template Editor for Method Body -->
            <ZoomView>
              <TemplateEditor
                :template="method.body"
                @set="(code) => updateMethodBody(index, code)"
              />
            </ZoomView>
          </div>
        </div>
        <!-- Remove Method Button -->
        <button
          @click="removeMethod(index)"
          class="text-gray-500 hover:text-red-500 self-end mt-2"
          aria-label="Remove method"
        >
          <i class="fas fa-trash"></i>
        </button>
      </div>
    </div>

    <!-- Message when no methods are added -->
    <div v-if="localMethods.length === 0" class="opacity-50 pt-1">
      No methods added yet. Click the +Add button to add a new method.
    </div>
  </div>
</template>

<script>
import TemplateEditor from "./TemplateEditor.vue";
import ZoomView from "../ui/ZoomView.vue";

export default {
  name: "MethodsComponent",
  props: {
    // The 'methods' prop receives an array of method data
    methods: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // 'localMethods' stores a local copy of methods for easy editing
      localMethods: [],
      // 'showModal' controls the visibility of the modal
      showModal: false,
      // 'newMethod' holds the data for a new method being added
      newMethod: { name: "", body: "" },
    };
  },
  components: {
    TemplateEditor,
    ZoomView,
  },
  methods: {
    /**
     * Toggles the visibility of the modal.
     * Resets the 'newMethod' object if the modal is closed.
     */
    toggleModal() {
      this.showModal = !this.showModal;
      if (!this.showModal) {
        this.newMethod = { name: "", body: "" };
      }
    },

    /**
     * Adds a new method to the list by emitting the 'add' event.
     * If the method name is not empty, it sends the new method name.
     */
    addMethod() {
      if (this.newMethod.name.trim()) {
        let name = this.newMethod.name.trim();
        this.$emit("add", { name });
        this.toggleModal();
      }
    },

    /**
     * Removes a method at a given index by emitting the 'remove' event.
     * A confirmation prompt is displayed before removing the method.
     *
     * @param {number} index - Index of the method to remove
     */
    removeMethod(index) {
      if (window.confirm("Are you sure you want to remove this method?")) {
        this.$emit("remove", { index });
      }
    },

    /**
     * Updates a specific field of a method based on the field name and value.
     * This method is triggered when the user edits method properties.
     *
     * @param {number} index - Index of the method being edited
     * @param {string} field - The name of the field being updated (e.g., 'name')
     * @param {any} value - The new value for the field
     */
    updateMethodData(index, field, value) {
      this.$emit("update", { key: field, index, value: value });
    },

    /**
     * Updates the body of a method when edited using the TemplateEditor component.
     *
     * @param {number} index - Index of the method being edited
     * @param {string} body - The new body content for the method
     */
    updateMethodBody(index, body) {
      this.$emit("update", { key: "body", index, value: body });
    },
  },
  watch: {
    /**
     * Watches for changes in the 'methods' prop and updates the 'localMethods' array.
     *
     * @param {Array} newMethods - The new array of methods passed from the parent
     */
    methods: {
      handler(newMethods) {
        this.localMethods = [...newMethods];
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    /**
     * Initializes the 'localMethods' array with the methods prop.
     */
    this.localMethods = [...this.methods];
  },
};
</script>

<style scoped>
.self-settings-add-btn {
  font-weight: bold;
  transition: background-color 0.2s;
}

.zoom-view-fixed {
  height: 80px;
  overflow-y: auto;
}
</style>
