<template>
  <div>
    <div class="flex justify-end mb-4">
      <div class="relative">
        <!-- Add Button styled like PropsTab -->
        <button
          @click="toggleModal"
          class="self-settings-add-btn flex items-center"
        >
          <span class="text-lg mr-1">+</span> Add
        </button>

        <!-- Modal Dialog for Adding New Constant -->
        <div
          v-if="showModal"
          class="absolute right-full mr-2 top-0 bg-white border border-gray-200 rounded-lg shadow-md p-3 pb-2.5 z-10 w-64"
        >
          <input
            v-model="newHeader.name"
            type="text"
            placeholder="Enter header name"
            class="w-full mb-3 px-2 py-1 border rounded text-xs custom-focus"
          />
          <div class="flex justify-end space-x-2">
            <button
              @click="toggleModal"
              class="ml-2 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-1 px-2 rounded text-xs self-settings-cancel-btn"
            >
              Cancel
            </button>
            <button
              @click="addHeader"
              class="text-white font-bold py-1 px-2 rounded text-xs self-settings-add-btn"
              :class="
                !newHeader.name
                  ? 'cursor-not-allowed bg-blue-300'
                  : 'bg-blue-400 hover:bg-blue-500'
              "
              :disabled="!newHeader.name"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-for="(header, idx) in headers" :key="idx" class="mt-3">
      <div class="flex items-center gap-3 mb-3">
        <div class="w-full">
          <input
            :value="header.name"
            @input="
              setRequestValue('headers', idx, 'name', $event.target.value)
            "
            type="text"
            placeholder="Key"
            class="w-full py-2 px-3 ml-1 border border-blue-100 custom-focus rounded-md"
          />
        </div>
        <div class="w-full">
          <input
            :value="header.default_value"
            @input="
              setRequestValue(
                'headers',
                idx,
                'default_value',
                $event.target.value
              )
            "
            type="text"
            placeholder="Value"
            class="w-full py-2 px-3 border border-blue-100 custom-focus rounded-md"
          />
        </div>
        <div>
          <button
            @click="removeRequestData('headers', idx)"
            class="text-gray-500 hover:text-red-500 ml-2"
          >
            <i class="fas fa-trash"></i>
          </button>
        </div>
      </div>
    </div>
    <div v-if="headers.length === 0" class="opacity-50 pt-1">
      No headers added yet. Click the +Add button to add a new header.
    </div>
  </div>
</template>

<script>
export default {
  name: "RequestHeadersTab",
  props: ["item"],
  data() {
    return {
      showModal: false,
      newHeader: {
        name: "",
        default_value: "",
      },
    };
  },
  computed: {
    headers() {
      return this.item.resolve.headers || [];
    },
  },

  methods: {
    /**
     * Toggles the visibility of the modal for adding a new header.
     * If the modal is closed, it resets the `newHeader` data.
     */
    toggleModal() {
      this.showModal = !this.showModal;
      if (!this.showModal) {
        this.newHeader = { name: "", default_value: "" };
      }
    },

    /**
     * Adds a new header to the `resolve.headers` array in the `item` prop.
     * It first creates a clone of the item to avoid mutating the prop directly,
     * then adds the new header and emits the updated item.
     */
    addHeader() {
      const clone = JSON.parse(JSON.stringify(this.item));
      if (!clone.resolve.headers) {
        clone.resolve.headers = [];
      }
      clone.resolve.headers.push({
        name: this.newHeader.name,
        default_value: this.newHeader.default_value,
      });
      this.$emit("update:item", clone);
      this.toggleModal();
    },

    /**
     * Updates a specific field in the header at the given index (`idx`).
     * This function emits the updated `item` object after modifying the field.
     *
     * @param {string} type - The type of data being updated (e.g., 'headers').
     * @param {number} idx - The index of the header in the array.
     * @param {string} field - The specific field to update (e.g., 'name', 'default_value').
     * @param {any} value - The new value for the specified field.
     */
    setRequestValue(type, idx, field, value) {
      const clone = { ...this.item };
      clone.resolve[type][idx][field] = value;
      this.$emit("update:item", clone);
    },

    /**
     * Removes the header at the specified index (`idx`) from the `resolve.headers` array.
     * It creates a deep clone of the item to avoid mutating the original prop directly,
     * then removes the header and emits the updated item.
     *
     * @param {string} type - The type of data being updated (e.g., 'headers').
     * @param {number} idx - The index of the header to remove.
     */
    removeRequestData(type, idx) {
      const clone = JSON.parse(JSON.stringify(this.item));
      clone.resolve[type].splice(idx, 1);
      this.$emit("update:item", clone);
    },
  },
};
</script>
