<template>
  <div class="mx-1">
    <div class="flex sm:flex-nowrap gap-3 mb-3">
      <div class="w-full md:w-1/2">
        <input
          :value="item.key"
          @input="setField('key', $event.target.value)"
          type="text"
          placeholder="Key"
          class="w-full py-2 px-3 border border-blue-100 custom-focus rounded-md"
        />
      </div>
      <div class="w-full md:w-1/2 relative">
        <ZoomView v-slot="{ modal }">
          <textarea
            :value="item.default_value"
            @input="setField('default_value', $event.target.value)"
            :rows="modal ? 20 : 1"
            placeholder="Default value"
            :class="[
              'w-full py-2 px-3 border border-blue-100 custom-focus rounded-md default-value-text-area',
              { 'h-full resize-none': modal },
            ]"
          ></textarea>
        </ZoomView>

        <!-- <button
          class="text-black-500 hover:text-blue-700 absolute right-1 top-0"
        >
          <i class="fas fa-expand"></i>
        </button> -->
      </div>
    </div>

    <div class="flex items-center gap-2 mb-3">
      <input
        :checked="item.use_relay"
        @change="setField('use_relay', $event.target.checked)"
        type="checkbox"
        :id="'check-box-' + id"
        class="h-4 w-4 custom-checkbox"
      />
      <label :for="'check-box-' + id" class="text-sm">
        Make api call on remote
      </label>
    </div>
  </div>
</template>

<script>
import ZoomView from "../../ui/ZoomView.vue";

export default {
  name: "SettingsTab",
  props: ["item", "id"],
  components: {
    ZoomView,
  },
  methods: {
    /**
     * Updates the specified field of the item object and emits the updated item.
     * This method performs a deep clone of the item to avoid mutating the original prop directly.
     *
     * @param {string} field - The field to be updated in the item object (e.g., 'key', 'default_value', 'use_relay').
     * @param {any} value - The new value to set for the specified field.
     */
    setField(field, value) {
      // Create a deep clone of the item to avoid mutating the original prop
      const clone = JSON.parse(JSON.stringify(this.item));

      // Update the field with the new value
      clone[field] = value;

      // Emit the updated item object to the parent component
      this.$emit("update:item", clone);
    },
  },
};
</script>
