<template>
  <div>
    <div class="flex justify-end mb-4">
      <div class="relative">
        <button
          @click="toggleModal"
          class="self-settings-add-btn flex items-center"
        >
          <span class="text-lg mr-1">+</span> Add
        </button>

        <!-- Modal Dialog for Adding New Param -->
        <div
          v-if="showModal"
          class="absolute right-full mr-2 top-0 bg-white border border-gray-200 rounded-lg shadow-md p-3 pb-2.5 z-10 w-64"
        >
          <input
            v-model="newBody.name"
            type="text"
            placeholder="Enter body name"
            class="w-full mb-3 px-2 py-1 border rounded text-xs custom-focus"
          />
          <div class="flex justify-end space-x-2">
            <button
              @click="toggleModal"
              class="ml-2 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-1 px-2 rounded text-xs self-settings-cancel-btn"
            >
              Cancel
            </button>
            <button
              @click="addBodyParam()"
              class="text-white font-bold py-1 px-2 rounded text-xs self-settings-add-btn"
              :class="
                !newBody.name
                  ? 'cursor-not-allowed bg-blue-300'
                  : 'bg-blue-400 hover:bg-blue-500'
              "
              :disabled="!newBody.name"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-for="(bodyObject, idx) in body" :key="idx" class="mt-3">
      <div class="flex items-center gap-3 mb-3">
        <div class="w-full">
          <input
            :value="bodyObject.name"
            @input="
              setRequestValue('body_params', idx, 'name', $event.target.value)
            "
            type="text"
            placeholder="Key"
            class="w-full py-2 px-3 ml-1 border border-blue-100 custom-focus rounded-md"
          />
        </div>
        <div class="w-full">
          <select
            :value="bodyObject.source"
            placeholder="Select"
            @input="
              setRequestValue('body_params', idx, 'source', $event.target.value)
            "
            class="w-full py-2 px-3 border border-blue-100 custom-focus rounded-md"
          >
            <option value="query">query</option>
            <option value="prop">prop</option>
            <option value="data">data</option>
          </select>
        </div>
        <div class="w-full">
          <input
            :value="bodyObject.default_value"
            @input="
              setRequestValue(
                'body_params',
                idx,
                'default_value',
                $event.target.value
              )
            "
            type="text"
            placeholder="Value"
            class="w-full py-2 px-3 border border-blue-100 custom-focus rounded-md"
          />
        </div>
        <div>
          <button
            @click="removeBodyObject(index)"
            class="text-gray-500 hover:text-red-500 ml-2"
          >
            <i class="fas fa-trash"></i>
          </button>
        </div>
      </div>
    </div>
    <div v-if="body.length === 0" class="opacity-50 pt-1">
      No body parameters added yet. Click the +Add button to add a new body
      parameter.
    </div>
  </div>
</template>

<script>
export default {
  name: "BodyTab",
  props: ["item"],
  data() {
    return {
      showModal: false,
      newBody: {
        name: "",
        source: "query",
        default_value: "",
      },
    };
  },
  computed: {
    body() {
      return this.item.resolve.body_params || [];
    },
  },
  methods: {
    /**
     * Toggles the visibility of the modal for adding a new body parameter.
     * If the modal is closed, resets the `newBody` object.
     */
    toggleModal() {
      this.showModal = !this.showModal;
      if (!this.showModal) {
        this.newBody = { name: "", source: "query", default_value: "" };
      }
    },

    /**
     * Adds a new body parameter to the `body_params` array of the item.
     * Clones the `item` to avoid mutation, adds the new body parameter,
     * and emits the updated item to the parent.
     */
    addBodyParam() {
      const clone = JSON.parse(JSON.stringify(this.item));
      clone.resolve.body_params = clone.resolve.body_params || [];
      clone.resolve.body_params.push({
        name: this.newBody.name,
        source: this.newBody.source,
        default_value: this.newBody.default_value,
      });
      this.$emit("update:item", clone);
      this.toggleModal();
    },

    /**
     * Updates a specific field of a body parameter in the `body_params` array.
     * Clones the `item` to avoid mutation, updates the field,
     * and emits the updated item to the parent.
     *
     * @param {string} type - The array type (e.g., 'body_params').
     * @param {number} idx - The index of the parameter to update.
     * @param {string} field - The field to update (e.g., 'name', 'source', 'default_value').
     * @param {string} value - The new value to set for the field.
     */
    setRequestValue(type, idx, field, value) {
      const clone = { ...this.item };
      clone.resolve[type][idx][field] = value;
      this.$emit("update:item", clone);
    },

    /**
     * Removes a body parameter from the `body_params` array at the specified index.
     * Clones the `item` to avoid mutation, removes the parameter,
     * and emits the updated item to the parent.
     *
     * @param {number} idx - The index of the body parameter to remove.
     */
    removeBodyObject(idx) {
      const clone = JSON.parse(JSON.stringify(this.item));
      clone.resolve.body_params.splice(idx, 1);
      this.$emit("update:item", clone);
    },
  },
};
</script>
