<template>
  <div class="p-4">
    <!-- Container for component name and global checkbox -->
    <div class="flex items-center justify-between">
      <div class="flex items-center space-x-4 flex-1">
        <!-- Label and input for component name -->
        <label
          for="name"
          class="text-sm font-medium text-gray-700 whitespace-nowrap"
        >
          Component Name:
        </label>
        <input
          type="text"
          id="name"
          v-model="name"
          :disabled="is_global"
          class="w-1/2 py-2 px-3 border border-gray-300 rounded-md custom-focus"
          placeholder="Enter component name"
        />

        <!-- Conditional checkbox for global component -->
        <div
          v-if="currentComponent.name !== 'app'"
          class="flex items-center ml-2"
        >
          <input
            class="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 ml-5 custom-checkbox"
            v-model="is_global"
            :disabled="isComponentView"
            type="checkbox"
            id="isGlobalComponent"
          />
          <label class="ml-2 text-sm" for="isGlobalComponent">
            This is a global component
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComponentSettings",
  props: {
    /**
     * The current component object, which contains the name of the component.
     * It is used to display the current settings of the component.
     */
    currentComponent: {
      type: Object,
      default: () => ({}),
    },

    /**
     * The settings object passed from the parent component that contains the current settings for the component.
     * This is used to update the local state with the latest settings.
     */
    settings: {
      type: Object,
      required: true,
    },

    /**
     * Flag to indicate if the component is in view-only mode.
     * When true, certain actions like editing the component name and toggling global status are disabled.
     */
    isComponentView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      /**
       * Local data variables to store the component's name and global status.
       */
      is_global: false, // Tracks if the component is marked as global
      name: "", // Tracks the component name
    };
  },
  watch: {
    /**
     * Watches the `settings` prop and updates the local data properties (`is_global` and `name`)
     * whenever the settings are changed in the parent component.
     * The handler checks if a setting exists on the component's data and updates it accordingly.
     */
    settings: {
      handler(newSettings) {
        Object.keys(newSettings).forEach((field) => {
          if (field in this) {
            this[field] = newSettings[field]; // Update corresponding local data property
          }
        });
      },
      immediate: true, // Trigger immediately when component is mounted
    },

    /**
     * Watches changes to `is_global` and emits the updated global status to the parent component.
     * This triggers the "settings:change" event to propagate the new global status.
     */
    is_global(newValue) {
      this.$emit("settings:change", { is_global: newValue });
    },

    /**
     * Watches changes to `name` and emits the updated component name to the parent component.
     * This triggers the "settings:change" event to propagate the new name.
     */
    name(newValue) {
      this.$emit("settings:change", { name: newValue });
    },
  },
};
</script>
