<template>
  <div class="p-4">
    <!-- Button to trigger modal to add new prop -->
    <div class="flex justify-end mb-4">
      <div class="relative">
        <button
          @click="toggleModal"
          class="self-settings-add-btn flex items-center"
        >
          <span class="text-lg mr-1">+</span> Add Props
        </button>

        <!-- Modal Dialog for adding new prop -->
        <div
          v-if="showModal"
          class="absolute right-full mr-2 top-0 bg-white border border-gray-200 rounded-lg shadow-md p-3 pb-2.5 z-10 w-64"
        >
          <input
            v-model="newName"
            type="text"
            placeholder="Enter new prop"
            class="w-full mb-3 px-2 py-1 border rounded text-xs custom-focus"
          />
          <div class="flex justify-end space-x-2">
            <button
              @click="toggleModal"
              class="ml-2 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-1 px-2 rounded text-xs self-settings-cancel-btn"
            >
              Cancel
            </button>
            <button
              @click="add"
              class="text-white font-bold py-1 px-2 rounded text-xs self-settings-add-btn"
              :class="
                !newName
                  ? 'cursor-not-allowed bg-blue-300'
                  : 'bg-blue-400 hover:bg-blue-500'
              "
              :disabled="!newName"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Display list of added props -->
    <div class="grid grid-cols-2 gap-4">
      <div
        v-for="(item, index) in localProps"
        :key="index"
        class="flex items-center gap-2"
      >
        <!-- Input field for editing a prop -->
        <input
          v-model="localProps[index]"
          type="text"
          class="w-full px-3 py-2 border border-gray-300 custom-focus rounded-md"
          placeholder="Enter new prop"
        />
        <!-- Button to remove a prop -->
        <button
          @click="remove(index)"
          class="text-gray-500 hover:text-red-500 ml-2"
        >
          <i class="fas fa-trash"></i>
        </button>
      </div>
    </div>

    <!-- Message when no props have been added -->
    <div v-if="localProps.length === 0" class="opacity-50 pt-1">
      No props added yet. Click the +Add button to add a new prop.
    </div>
  </div>
</template>

<script>
export default {
  name: "PropsTab",
  props: {
    /**
     * The list of props passed from the parent component.
     * This is an array that contains the props that are displayed and edited in this component.
     */
    propsList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      /**
       * The local state for props, initialized from the parent `propsList`.
       * This array is used to hold and modify the props in the component.
       */
      localProps: [...this.propsList],

      /**
       * A boolean flag to control the visibility of the modal.
       * When true, the modal to add a new prop is shown.
       */
      showModal: false,

      /**
       * The name of the new prop to be added.
       * Bound to the input field in the modal.
       */
      newName: "",
    };
  },
  watch: {
    propsList: {
      handler(newProps) {
        // Only update localProps if newProps is different from current localProps
        if (JSON.stringify(newProps) !== JSON.stringify(this.localProps)) {
          this.localProps = [...newProps]; // Update the local props array when the parent propsList changes
        }
      },
      deep: true, // Watch for deep changes (modifications to nested properties)
    },
    /**
     * Watches the `localProps` array for changes.
     * When any prop is added, modified, or removed, the updated list is emitted to the parent.
     */
    localProps: {
      handler(newProps) {
        this.$emit("set", newProps); // Emit the updated list of props to the parent
      },
      deep: true, // Watch for deep changes (modifications to nested properties)
    },
  },
  methods: {
    /**
     * Toggles the visibility of the modal.
     * If the modal is open, it will close it; otherwise, it will open the modal.
     * Also clears the `newName` field when closing the modal.
     */
    toggleModal() {
      this.showModal = !this.showModal;
      if (!this.showModal) {
        this.newName = ""; // Reset new prop name when closing the modal
      }
    },

    /**
     * Adds a new prop to the `localProps` array.
     * Emits the updated `localProps` array to the parent.
     * Clears the input field and closes the modal once the prop is added.
     */
    add() {
      if (this.newName.trim()) {
        this.localProps.push(this.newName); // Add the new prop to the array
        this.$emit("set", this.localProps); // Emit updated props to the parent
        this.toggleModal(); // Close the modal
      }
    },

    /**
     * Removes a prop from the `localProps` array.
     * A confirmation dialog is shown before removal.
     * Emits the updated `localProps` array to the parent once the prop is removed.
     *
     * @param {number} index - The index of the prop to remove
     */
    remove(index) {
      // Show confirmation dialog before removing the prop
      if (window.confirm("Are you sure you want to remove this prop?")) {
        this.localProps.splice(index, 1); // Remove the prop from the array
        this.$emit("set", this.localProps); // Emit updated props to the parent
      }
    },
  },
  mounted() {
    /**
     * Listens for clicks outside the modal to close it.
     * If the modal is open and a click occurs outside the modal, the modal will close.
     */
    document.addEventListener("click", (event) => {
      if (this.showModal && !event.target.closest(".relative")) {
        this.toggleModal();
      }
    });
  },
  beforeUnmount() {
    /**
     * Clean up event listener when the component is destroyed.
     * This prevents memory leaks.
     */
    document.removeEventListener("click", this.toggleModal);
  },
};
</script>
