<template>
  <div class="p-4">
    <div class="flex justify-end mb-4">
      <div class="relative">
        <!-- Button to toggle modal for adding a new constant -->
        <button
          @click="toggleModal"
          class="self-settings-add-btn flex items-center"
        >
          <span class="text-lg mr-1">+</span> Add Constant Data
        </button>

        <!-- Modal Dialog for Adding New Constant -->
        <div
          v-if="showModal"
          class="absolute right-full mr-2 top-0 bg-white border border-gray-200 rounded-lg shadow-md p-3 pb-2.5 z-10 w-64"
        >
          <!-- Input for the constant name -->
          <input
            v-model="newConstant.key"
            type="text"
            placeholder="Enter constant name"
            class="w-full mb-3 px-2 py-1 border rounded text-xs custom-focus"
          />
          <div class="flex justify-end space-x-2">
            <!-- Cancel button to close the modal -->
            <button
              @click="toggleModal"
              class="ml-2 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-1 px-2 rounded text-xs self-settings-cancel-btn"
            >
              Cancel
            </button>
            <!-- Add button to add the constant -->
            <button
              @click="addConstant"
              class="text-white font-bold py-1 px-2 rounded text-xs self-settings-add-btn"
              :class="
                !newConstant.key
                  ? 'cursor-not-allowed bg-blue-300'
                  : 'bg-blue-400 hover:bg-blue-500'
              "
              :disabled="!newConstant.key"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Render each constant and allow editing -->
    <div v-for="(constant, index) in localConstants" :key="index">
      <div class="rounded-lg py-2 flex items-center gap-4">
        <!-- Input for constant name -->
        <div class="flex-grow flex items-center gap-2">
          <label class="text-gray-700 font-medium w-16">Name:</label>
          <input
            v-model="constant.key"
            type="text"
            class="flex-grow px-3 py-2 border border-gray-300 custom-focus rounded-md"
            placeholder="Enter constant name"
            @input="updateConstantData(constant)"
          />
        </div>
        <!-- Input for constant value -->
        <div class="flex-grow flex items-center gap-2">
          <label class="text-gray-700 font-medium w-16">Value:</label>
          <input
            v-model="constant.default_value"
            type="text"
            class="flex-grow px-3 py-2 border border-gray-300 custom-focus rounded-md"
            placeholder="Enter default value"
            @input="updateConstantData(constant)"
          />
        </div>
        <!-- Button to remove the constant -->
        <button
          @click="removeConstant(constant)"
          class="text-gray-500 hover:text-red-500 ml-2"
          aria-label="Remove constant"
        >
          <i class="fas fa-trash"></i>
        </button>
      </div>
    </div>
    <!-- Message when no constants are added -->
    <div v-if="localConstants.length === 0" class="opacity-50 pt-1">
      No constants added yet. Click the +Add button to add a new constant.
    </div>
  </div>
</template>

<script>
export default {
  name: "ConstantDataTab",
  props: {
    constants: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localConstants: [],
      showModal: false,
      newConstant: { key: "", default_value: "", type: "const" },
    };
  },
  methods: {
    /**
     * Toggles the visibility of the modal for adding new constant data.
     * Resets the new constant data when closing the modal.
     */
    toggleModal() {
      this.showModal = !this.showModal;
      if (!this.showModal) {
        this.newConstant = { key: "", default_value: "", type: "const" };
      }
    },

    /**
     * Adds the new constant to the list when the 'Add' button is clicked.
     * Emits an 'add' event with the constant data to the parent.
     */
    addConstant() {
      if (this.newConstant.key.trim()) {
        let name = this.newConstant.key.trim();
        let type = this.constants.type;
        this.$emit("add", {
          name,
          type,
        });
        this.toggleModal();
      }
    },

    /**
     * Removes a constant from the list.
     * Displays a confirmation dialog before emitting the 'remove:item' event.
     * @param {Object} constant - The constant object to remove.
     */
    removeConstant(constant) {
      if (window.confirm("Are you sure?")) {
        this.$emit("remove:item", constant.idx);
      }
    },

    /**
     * Updates the constant data when a user makes changes in the input fields.
     * Emits an 'update:item' event with the updated constant.
     * @param {Object} constant - The constant object being updated.
     */
    updateConstantData(constant) {
      this.$emit("update:item", constant);
    },
  },
  watch: {
    /**
     * Watcher to track changes in the 'constants' prop.
     * Updates the localConstants data whenever the prop changes.
     * @param {Object} newConstants - New constant data passed via prop.
     */
    constants: {
      handler(newConstants) {
        this.localConstants = [...newConstants.data];
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    /**
     * Sets the initial local constants from the constants prop when the component is created.
     */
    this.localConstants =
      this.constants.data.length > 0 ? [...this.constants.data] : [];
  },
};
</script>
