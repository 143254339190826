<template>
  <div class="mx-1">
    <div
      class="flex items-center mb-3 border border-blue-100 custom-focus rounded-md"
    >
      <select
        v-model="localItem.method"
        @input="setField('method', $event.target.value)"
        class="px-3 mr-3 py-2.5 bg-white border-blue-100 custom-focus rounded-md"
      >
        <option value="POST">POST</option>
        <option value="GET">GET</option>
      </select>
      <!-- Divider -->
      <div class="h-6 w-px bg-gray-300"></div>
      <input
        v-model="localItem.resolve.source_url"
        @input="setSourceUrl($event.target.value)"
        type="text"
        class="px-3 py-2.5 w-full custom-focus rounded-md"
        placeholder="Enter URL"
      />
    </div>
    <div class="flex justify-between items-center mt-3">
      <ul class="list-none mb-3">
        <li
          v-for="tab in tabs"
          :key="tab"
          @click="selectTab(tab)"
          class="inline-block mr-4 pr-4 border-r border-blue-100 cursor-pointer"
          :class="{
            'selected-sub-remote-item font-semibold': selectedTab === tab,
          }"
        >
          {{ tab }}
        </li>
      </ul>
    </div>

    <SettingsTab
      v-if="selectedTab === 'Settings'"
      :item="localItem"
      :id="localItem.idx"
      @update:item="$emit('update', { idx: localItem.idx, data: $event })"
    />

    <RequestHeadersTab
      v-if="selectedTab === 'Request Headers'"
      :item="localItem"
      @update:item="$emit('update', { idx: localItem.idx, data: $event })"
    />

    <ParamsTab
      v-if="selectedTab === 'Params'"
      :item="localItem"
      @update:item="$emit('update', { idx: localItem.idx, data: $event })"
    />

    <BodyTab
      v-if="selectedTab === 'Body'"
      :item="localItem"
      @update:item="$emit('update', { idx: localItem.idx, data: $event })"
    />

    <PreviewTab
      v-if="selectedTab === 'Preview'"
      :item="localItem"
      @update:item="$emit('update', { idx: localItem.idx, data: $event })"
    />
  </div>
</template>

<script>
import SettingsTab from "@/components/remoteDataComponents/SettingsTab.vue";
import RequestHeadersTab from "@/components/remoteDataComponents/RequestHeadersTab.vue";
import ParamsTab from "@/components/remoteDataComponents/ParamsTab.vue";
import BodyTab from "@/components/remoteDataComponents/BodyTab.vue";
import PreviewTab from "@/components/remoteDataComponents/PreviewTab.vue";

export default {
  name: "RemoteDataSubTab",
  props: ["item"],
  data() {
    return {
      localItem: { ...this.item },
      tabs: ["Settings", "Request Headers", "Params", "Body", "Preview"],
      selectedTab: "Settings",
    };
  },
  components: {
    SettingsTab,
    RequestHeadersTab,
    ParamsTab,
    BodyTab,
    PreviewTab,
  },
  methods: {
    /**
     * Method to select a tab and update the selectedTab value.
     * This method is triggered when a tab is clicked.
     * @param {string} tab - The name of the tab to be selected
     */
    selectTab(tab) {
      this.selectedTab = tab;
    },
    /**
     * Method to update a specific field of the item.
     * Clones the item to avoid mutation, updates the field,
     * and emits the updated item to the parent.
     * @param {string} field - The field to update (e.g., 'method', 'resolve.source_url').
     * @param {string} value - The new value to set for the field.
     */
    setField(field, value) {
      this.localItem[field] = value;
      this.$emit("update", { idx: this.localItem.idx, data: this.localItem });
    },
    /**
     * Method to update the source URL of the item.
     * this.localItems the item to avoid mutation, updates the source URL,
     * and emits the updated item to the parent.
     * @param {string} url - The new source URL to set for the item.
     */
    setSourceUrl(url) {
      this.localItem.resolve.source_url = url;
      this.$emit("update", { idx: this.localItem.idx, data: this.localItem });
    },
  },
  watch: {
    /**
     * Watcher to track changes to the 'item' prop.
     * If 'item' changes, it updates 'localItem' accordingly.
     */
    item(newValue) {
      this.localItem = { ...newValue };
    },
  },
};
</script>

<style scoped>
.selected-sub-remote-item {
  color: #00aeef !important; /* Custom styling for selected tab */
}
</style>
